import './Vacancy.css'
import { useEffect, useRef, useState } from 'react'


export default function Vacancy(){
    const vacancyRef = useRef<HTMLDivElement>(null)
    const [isVisible, setIsVisible] = useState<boolean>(false)

    useEffect(()=>{
        let vacancyObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
        })

        function animation(entries: Array<IntersectionObserverEntry>){
            entries.forEach((entry: IntersectionObserverEntry)=>{
                entry.isIntersecting && vacancyRef.current && setIsVisible(true)
                entry.isIntersecting && vacancyRef.current && vacancyObserver.unobserve(vacancyRef.current)
            })
        }

        vacancyRef.current && vacancyObserver.observe(vacancyRef.current)
    })

    return(
        <>
        <div className={isVisible ? 'Vacancy_container' : 'Vacancy_container Vacancy_container_hidden'} ref={vacancyRef}>

            <div className='vacancy_text_header'>Пришлите свое резюме и мы свяжемся с вами в случае появления подходящих вакансий!</div>

            <div className='vacancy_header'>Телефон</div>
            <div className='vacancy_text'>+7(495)150-90-75 доб. 13</div>

            <div className='vacancy_header'>E-mail</div>
            <div className='vacancy_text'>hr@rrk-logistik.ru</div>

            <div className='vacancy_footer'>Мы будем рады видеть Вас в нашей команде!</div>
        </div>
        </>
    )
}