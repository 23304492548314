import { useEffect, useRef, useState } from 'react'
import './ServiceCard.css'

interface IServiceCard{
    serviceName: string,
    plusses: Array<string>,
    img: String,
    delay: number 
}

export default function ServiceCard({serviceName, plusses, img, delay}: IServiceCard){
    const ServiceCardRef = useRef<HTMLDivElement>(null)
    const[isVisible, setIsVisible] = useState<boolean>(false)
    const[isMouseOver, setIsMouseOver] = useState<boolean>(false)
    const plussesRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        let ServiceCardObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        })

        function animation(entries: Array<IntersectionObserverEntry>){
            entries.forEach((entry: IntersectionObserverEntry)=>{
                setIsVisible(entry.isIntersecting);
                entry.isIntersecting && ServiceCardRef.current && ServiceCardObserver.unobserve(ServiceCardRef.current)
            })
        }

        ServiceCardRef.current && ServiceCardObserver.observe(ServiceCardRef.current)
    }, [])

    return(
        <>
            <div className={isVisible ? 'ServiceCard_container' : 'ServiceCard_container ServiceCard_start_position'} 
            ref={ServiceCardRef} onMouseOver={()=>setIsMouseOver(true)} onMouseLeave={()=>setIsMouseOver(false)}
            style={{backgroundImage: `url(${img})`, transitionDelay: `${delay}s`}}>
                <div className='ServiceCard_mask'>
                    <div className='ServiceCard_serviceName'>{serviceName}</div>
                    <div className='ServiceCard_horizontal'></div>
                    <div className='ServiceCard_plusses' ref={plussesRef} style={(!isMouseOver && plussesRef.current) ? {marginBottom:`-${plussesRef.current.clientHeight+6}px`, opacity: `0`} : {marginBottom:`10px`, opacity: `1`}}>
                        {plusses.map((plus)=>{
                            return(<div className='ServiceCard_plus'>
                                <div className='ServiceCard_dot'></div>
                                <div className='ServiceCard_plus_text'>{plus}</div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}