import { useEffect, useRef } from 'react'
import './Numbers.css'

interface INumber{
    text: string,
    targetNumber: number,
    metric?: string
}

export default function Number({text, targetNumber, metric}:INumber){
    const NumberRef = useRef<any>(null)

    function countAnimation(targetNumber: number, element: HTMLDivElement, animationTime: number){
        let value = parseInt(element.innerHTML);
        let step = (targetNumber/animationTime);

        let interval = setInterval(()=>{
            value = value + step
            element.innerHTML = String(Math.round(value)) + ' ' + metric

            if(value >= targetNumber) {
                clearInterval(interval)
                element.innerHTML = String(targetNumber) + ' ' + metric
            }
        }, 1)
    }

    useEffect(()=>{
        let numberObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 1.00
        })

        function animation(entries: Array<IntersectionObserverEntry>){
            entries.forEach((entry: IntersectionObserverEntry)=>{
                (entry.isIntersecting && NumberRef.current) && (countAnimation(targetNumber, NumberRef.current, 391))
                entry.isIntersecting && NumberRef.current && numberObserver.unobserve(NumberRef.current)
            })
        }

        NumberRef.current && numberObserver.observe(NumberRef.current)
    })

    return(
        <>
        <div className='Number_container'>
            <div className='Number_number' ref={NumberRef}>0</div>
            <div className='Number_horizontal'></div>
            <div className='Number_text'>{text}</div>
        </div>
        </>
    )
}