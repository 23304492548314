import './HeaderButton.css';

interface IHeaderButton{
    text: string,
    index: number,
    activeButton: number,
    setTargetButton: Function,
    to:string,
    offset?: number
}

export default function HeaderButton({text, index, activeButton, setTargetButton, to, offset = -40}:IHeaderButton){
    
    return(
        <>
            <div className='HeaderButton_container' onClick={()=>setTargetButton(index)}>
                <div>{text}</div>
                {activeButton === index ? <div className='HeaderButton_underline' style={{width: `100%`}}></div>:
                <div className='HeaderButton_underline'></div>
                }
            </div>
        </>
    )
}