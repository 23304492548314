import { useEffect, useRef, useState } from 'react'
import './Contact.css'

interface IContact{
    icon: string,
    text: string
}

export default function Contact({icon, text}: IContact){
    const ContactRef = useRef<HTMLDivElement>(null)
    const[isVisible, setIsVisible] = useState<boolean>(false)
    const[copyText, setCopyText] = useState<string>('Скопировать')

    async function copyToClipboard(text: string){
        if('clipboard' in navigator){
            try{
                const type = "text/plain"
                const blob = new Blob([text], { type });
                const data = [new ClipboardItem({ [type]: blob })];
                return await navigator.clipboard.write(data)
            }
            catch(err: any){
                console.log(err)
                throw err
            }
        }
        else{
            return document.execCommand('copy', true, text);
        }
    }

    let permission:any  = 'clipboard-write'


    function handleCopyClick(){
        navigator.permissions.query({name: permission})
            .then(result => {
                console.log(result.state)
            })
        copyToClipboard(text)
            .then(()=>{
                setCopyText('Скопированно!')
                setTimeout(()=>{
                    setCopyText('Скопировать')
                },1000)
            })
            .catch((err: Error)=>{
                console.log(err)
            })
    }

    useEffect(()=>{
        let numberObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 0.6
        })

        function animation(entries: Array<IntersectionObserverEntry>){
            entries.forEach((entry: IntersectionObserverEntry)=>{
                setIsVisible(entry.isIntersecting)
                entry.isIntersecting && ContactRef.current && numberObserver.unobserve(ContactRef.current)
            })
        }

        ContactRef.current && numberObserver.observe(ContactRef.current)
    }, [])
    return(
    <>
        <div className={isVisible ? 'Contact_container' : 'Contact_container Contact_start_position'} ref={ContactRef}>
            <img src={icon} alt={`${icon}`} className='Contact_icon'></img>
            <div className='Contact_text' onClick={handleCopyClick}>{text}</div>
            <div className='Contact_hint' style={copyText === 'Скопированно!' ? {color: `rgb(0, 208, 66)`, border: `1px solid rgb(0, 208, 66)`} : {color: `rgb(62 95 255)`, border: `1px solid rgb(62 95 255)`}}>{copyText}</div>
        </div>
    </>
    )
}