import './Main.css'
import MainPage from './MainPage/MainPage'

interface IMain{
    setTargetButton: Function,
    setActiveButton: Function,
    targetButton: number
}

export default function Main({setTargetButton, setActiveButton, targetButton}:IMain){

    return(
        <>
            <div className='MainContent_container'>
                <MainPage setTargetButton={setTargetButton} setActiveButton={setActiveButton}
                targetButton={targetButton}></MainPage>
            </div>
        </>
    )
}