import './MainPage.css'
import storage from '../../images/storage.jpeg'
import TextHeader from './TextHeader/TextHeader'
import Number from './Numbers/Numbers'
import ServiceCard from './ServiceCard/ServiceCard'
import storeInner from '../../images/storeInner.jpeg'
import Contact from './Contact/Contact'
import web from '../../icons/web.png'
import phone from '../../icons/phone.png'
import mail from '../../icons/mail.png'
import logistics from '../../images/logitics.jpeg'
import scheme from '../../images/scheme.jpg'
import map from '../../images/map.jpeg'
import React, { useEffect, useRef, useState } from 'react'
import Gallery from './Gallery/Gallery'
import Vacancy from './Vacancy/Vacancy'

interface IMainPage{
    setTargetButton: Function,
    setActiveButton: Function,
    targetButton: number,
}

export default function MainPage({setTargetButton, setActiveButton, targetButton}:IMainPage){
    const[scrolled, setScrolled] = useState<number>(0)
    const[openMap, setOpenMap] = useState<number>(0)

    const companyRef = useRef<HTMLDivElement>(null)
    const serviceRef = useRef<HTMLDivElement>(null)
    const storageRef = useRef<HTMLDivElement>(null)
    const vacancyRef = useRef<HTMLDivElement>(null)
    const connectRef = useRef<HTMLDivElement>(null)

    function scrollToElement(targetElement: HTMLDivElement, offsetTop = 40){
        let distanceFromTop = targetElement.offsetTop
        window.scrollTo({top: distanceFromTop - offsetTop, behavior: `smooth`})
    }

    function intersectingElement(targetElement: HTMLDivElement, index: number, offsetTop: number){
        let elementHeight = targetElement.scrollHeight
        let distanceFromTop = targetElement.offsetTop

        let elStart = (distanceFromTop - offsetTop)
        let halfEl = elStart+(elementHeight/2)

        if((scrolled > elStart) && (scrolled < halfEl)){
            setActiveButton(index)
            setTargetButton(0)
        }
    }

    function isFullScrolled(){
        const documentHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;

        if ((scrolled + windowHeight) >= documentHeight) {
            setActiveButton(5)
            setTargetButton(0)
        }
    }

    useEffect(()=>{
        switch(targetButton){
            case 1: 
                window.scrollTo({top: 0, behavior: `smooth`})
                break;
            case 2:
                serviceRef.current && scrollToElement(serviceRef.current)
                break;
            case 3: 
                storageRef.current && scrollToElement(storageRef.current)
                break;
            case 4:
                vacancyRef.current && scrollToElement(vacancyRef.current, 90)
                break;
            case 5:
                connectRef.current && scrollToElement(connectRef.current)
                // window.scrollTo({top: 1000000, behavior: `smooth`})
                break;
        }
    }, [targetButton])

    useEffect(()=>{

    companyRef.current && intersectingElement(companyRef.current, 1, 100)
    serviceRef.current && intersectingElement(serviceRef.current, 2, 100)
    storageRef.current && intersectingElement(storageRef.current, 3, 100)
    vacancyRef.current && intersectingElement(vacancyRef.current, 4, 100)
    connectRef.current && intersectingElement(connectRef.current, 5, 100)
    isFullScrolled()

    }, [scrolled])

    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            setScrolled(window.scrollY)
        })
        return(()=>{
            window.removeEventListener('scroll', ()=>{
                setScrolled(window.scrollY)
            })
        })
    },[])


    return(
        <>
            <div className='MainPage_container'>
                <div className='MainPage_company_container' id='aboutCompany' ref={companyRef}>
                    <img className='MainPage_company_background' src={storage} alt='storage'></img>
                    <div className='MainPage_company_mask'>
                        <div className='MainPage_company_text_container'>
                            <div className='MainPage_company_header'>РРК ЛОГИСТИК</div>
                            <div className='MainPage_company_text' style={{transitionDelay: `0.5s`}}>
                            ООО « РРК Логистик» -  динамично развивающаяся компания, которая оказывает  услуги по хранению продовольственных грузов, требующих поддержания определенных температурных режимов, что гарантирует сохранность груза при продолжительном хранении.
Мы располагаем собственным новым (введен в эксплуатацию в 2024 году) складским комплексом класса «А», площадью 6 300  м2, основной специализацией которого является хранение мяса, птицы, рыбы и полуфабрикатов из них, а также мороженого и другой молочной продукции.

                            </div>
                        </div>
                    </div>
                </div>

                <div className='MainPage_company_numbers_container'>
                    <TextHeader text='Мы в цифрах' direction='Left'></TextHeader>
                    <div className='MainPage_company_numbers_main'>
                        <Number text='Общая площадь склада' targetNumber={6300} metric='м&sup2;'></Number>
                        <Number text='Зона экспедиции' targetNumber={1514} metric='м&sup2;'></Number>
                        <Number text='Ежесуточный приём' targetNumber={1200} metric='т'></Number>
                    </div>
                </div>

                <div className='MainPage_services_container' id = 'services' ref={serviceRef}>
                    <TextHeader text='Наши услуги' direction='Left'></TextHeader>
                    <div className='MainPage_services_main'>
                        <ServiceCard serviceName = 'Услуги склада' plusses = {['Услуги ответственного хранения','Ручная и механизированная погрузка/выгрузка', 'Кросс-докинг, прием и отгрузка товара без размещения в зоне длительного хранения', 'Подбор мелкоштучного товара, комплектация, маркировка, упаковка товара','Подготовка товара по требованиям РЦ', 'Учет товара и информационная поддержка WMS']} img={storeInner} delay={0.21}></ServiceCard>
                        <ServiceCard serviceName = 'Логистические услуги 3PL' plusses = {['Доставка конечному потребителю. ','Авто логистика, доставка продуктов питания', 'Контейнерная площадка для хранения контейнеров', 'Автостоянка для большегрузных машин','ЖД логистика из/в Москву через терминал Электроугли Расположение в границах крупнейшего контейнерного терминала ТЛЦ Восточный, дает возможность оперативно обеспечить доставку и выгрузку продукции на морозильный комплекс, существенно сэкономить на сроках и, самое главное, на стоимости автомобильной доставки']} img={logistics} delay={0.4}></ServiceCard>
                    </div>
                </div>

                <div className='MainPage_photos_container' id='storage' ref={storageRef}>
                    <TextHeader text='Склад' direction='Left'></TextHeader>
                    <Gallery></Gallery>
                </div>

                <div className='MainPage_vacancy_container' ref={vacancyRef}>
                    <TextHeader text='Вакансии' direction='Left'></TextHeader>
                    <div className='MainPage_vacancy_main'>
                        <Vacancy></Vacancy>
                    </div>
                </div>

                <div className='MainPage_connect_container' ref={connectRef}>
                    <TextHeader text='Контакты' direction='Left'></TextHeader>
                    <div className='MainPage_connect_main'>
                        <Contact text='+7(495)150-90-75' icon={phone}></Contact>
                        <Contact text='info@rrk-logistik.ru' icon={mail}></Contact>
                        <Contact text='rrk-logistik.ru' icon={web}></Contact>

                        <div className='connect_images_container'>
                            <div className='connect_image_container'>
                                <div className='connect_text'>«РРК Логистик» на карте</div>
                                <img src={map} alt="map" className='connect_scheme' id='connect_map' onClick={()=>setOpenMap(1)}/>
                            </div>
                            <div className='connect_image_container'>
                                <div className='connect_text'>Схема движения по территории ООО «ОТТ» на территорию ООО «РРК Логистик»</div>
                                <img src={scheme} alt="scheme" className='connect_scheme' onClick={()=>setOpenMap(2)}/>
                            </div>
                        </div>

                    </div>
                </div>

                {openMap === 1 && 
                <div className='connect_full_img' onClick={()=>setOpenMap(0)}>
                    <img src={map} alt="full map" className='full_map'/>
                </div>}
                {openMap === 2 && 
                <div className='connect_full_img' onClick={()=>setOpenMap(0)}>
                    <img src={scheme} alt="full map" className='full_map'/>
                </div>}
            </div>
        </>
    )
}