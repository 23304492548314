import { useEffect, useState } from 'react'
import './GalleryPage.css'

interface IGalleryPage{
    photo: string,
    index: number,
    isActive?:boolean,
}

export default function GalleryPage({photo, index, isActive}: IGalleryPage){
    return(
        <>
            <div className='GalleryPage_container'  style={ isActive ? 
                {opacity: `1` ,backgroundImage: `url(${photo})`} : 
                {opacity: `0` ,backgroundImage: `url(${photo})`}}>
            </div>
        </>
    )
}