import React, { useState } from 'react';
import './App.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Main from './Main/Main';

function App() {
    const[activeButton, setActiveButton] = useState<number>(1)
    const[targetButton, setTargetButton] = useState<number>(1)

    return (
      <div className="App">
        <Header activeButton={activeButton} setActiveButton={setActiveButton} setTargetButton={setTargetButton}></Header>
        <Main setTargetButton={setTargetButton} setActiveButton={setActiveButton} targetButton={targetButton}></Main>
        <Footer></Footer>
      </div>
    );
    }

export default App;
