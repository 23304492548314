import './Header.css'
import phone from '../icons/phone.png'
import mail from '../icons/mail.png'
import HeaderButton from './HeaderButton/HeaderButton'
import logo from '../icons/RRKLogo.png'

interface IHeader{
    activeButton: number,
    setActiveButton: Function,
    setTargetButton: Function
}

export default function Header({activeButton, setActiveButton, setTargetButton}:IHeader){

    return(
        <>
            <div className='Header_info'>
                <div className='Header_info_logo_container'>
                    <img src={logo} alt="logo" className='Header_info_logo_img' draggable = 'false'/>
                    <div className='Header_info_logo_text'>РРК ЛОГИСТИК</div>
                </div>
                <div className='Header_info_contacts'>
                    <div className='Header_info_contact'>
                        <img src={phone} alt="phone icon" className='Header_info_contact_icon' draggable = 'false'/>
                        <div className='Header_info_contact_text'>+7(495)150-90-75</div>
                    </div>
                    <div className='Header_info_contact'>
                        <img src={mail} alt="mail icon" className='Header_info_contact_icon' draggable = 'false'/>
                        <div className='Header_info_contact_text'>info@rrk-logistik.ru</div>
                    </div>
                </div>
            </div>
            <div className='Header_container'>
                <HeaderButton text='О компании' index={1} setTargetButton={setTargetButton}
                activeButton={activeButton} to = 'aboutCompany' offset={-130}
                ></HeaderButton>
                <HeaderButton text='Услуги' index={2} setTargetButton={setTargetButton}
                activeButton={activeButton} to = 'services'
                ></HeaderButton>
                <HeaderButton text='Склад' index={3} setTargetButton={setTargetButton}
                activeButton={activeButton} to = 'storage'
                ></HeaderButton>
                <HeaderButton text='Вакансии' index={4} setTargetButton={setTargetButton}
                activeButton={activeButton} to = 'dw'
                ></HeaderButton>
                <HeaderButton text='Контакты' index={5} setTargetButton={setTargetButton}
                activeButton={activeButton} to = 'scw'
                ></HeaderButton>
            </div>
        </>
    )
}