import './Footer.css'

export default function Footer(){
    // todo значок с к ррк логистик
    return(
        <>
            <div className='Footer_container'>
                <div className='Footer_line'>
                    <div className='Footer_text'>© 2024 Логистический комплекс «РРК ЛОГИСТИК»  Складское хранение и 3PL услуги.</div>
                    <div className='Footer_text'>Разработка сайта - Yaotone</div>
                </div>
                <div className='Footer_line'>
                    <div className='Footer_text' id='Footer_address'>Носовихинское шоссе, 26-й километр, 2, Электроугли, Богородский городской округ, Московская область</div>
                </div>
            </div>
        </>
    )
}