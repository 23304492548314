import './Gallery.css'
import GalleryPage from './GalleryPage'
import storage from '../../../images/storage.jpeg'
import fullStorage from '../../../images/fullStorage.jpeg'
import sideStorage from '../../../images/sideStorage.jpeg'
import arrow from '../../../icons/arrow.png'
import { useEffect, useRef, useState } from 'react'

export default function Gallery(){
    const galleryRef = useRef<HTMLDivElement>(null)
    
    const[isVisible, setIsVisible] = useState<boolean>(false)
    const[activePage, setActivePage] = useState<number>(0)
    const[isSlideShowActive, setIsSlideShowActive] = useState<boolean>(true)

    const[photoArray, setPhotoArray] = useState<Array<string>>([fullStorage, storage, sideStorage, 
        '', '', '', ''])

    function nextPage(){
        activePage ===(photoArray.length-1) ? setActivePage(0) : setActivePage(activePage + 1)
    }

    function prevPage(){
        activePage === 0 ? setActivePage((photoArray.length-1)) : setActivePage(activePage - 1) 
    }

    async function lazyLoad(){
        switch(activePage){
            case 1:
                if(photoArray[3] === ''){
                    const src = await import('../../../images/backStorage.jpeg')
                    setPhotoArray([...photoArray.slice(0, 3), src.default, ...photoArray.slice(4)])
                }
                break;
            case 2:
                if(photoArray[3] === ''){
                    const src = await import('../../../images/backStorage.jpeg')
                    setPhotoArray([...photoArray.slice(0, 3), src.default, ...photoArray.slice(4)])
                }
                if(photoArray[4] === ''){
                    const src = await import('../../../images/storageInner1.jpeg')
                    setPhotoArray([...photoArray.slice(0, 4), src.default, ...photoArray.slice(5)])
                }
                break;
            case 3:
                if(photoArray[3] === ''){
                    const src = await import('../../../images/backStorage.jpeg')
                    setPhotoArray([...photoArray.slice(0, 3), src.default, ...photoArray.slice(4)])
                }
                if(photoArray[4] === ''){
                    const src = await import('../../../images/storageInner1.jpeg')
                    setPhotoArray([...photoArray.slice(0, 4), src.default, ...photoArray.slice(5)])
                }
                if(photoArray[5] === ''){
                    const src = await import('../../../images/storageInner2.jpeg')
                    setPhotoArray([...photoArray.slice(0, 5), src.default, ...photoArray.slice(6)])
                }
                break;
            case 4:
                if(photoArray[3] === ''){
                    const src = await import('../../../images/backStorage.jpeg')
                    setPhotoArray([...photoArray.slice(0, 3), src.default, ...photoArray.slice(4)])
                }
                if(photoArray[4] === ''){
                    const src = await import('../../../images/storageInner1.jpeg')
                    setPhotoArray([...photoArray.slice(0, 4), src.default, ...photoArray.slice(5)])
                }
                if(photoArray[5] === ''){
                    const src = await import('../../../images/storageInner2.jpeg')
                    setPhotoArray([...photoArray.slice(0, 5), src.default, ...photoArray.slice(6)])
                }
                if(photoArray[6] === ''){
                    const src = await import('../../../images/storeInner.jpeg')
                    setPhotoArray([...photoArray.slice(0, 6), src.default])
                }
                break;
            case 5:
                if(photoArray[4] === ''){
                    const src = await import('../../../images/storageInner1.jpeg')
                    setPhotoArray([...photoArray.slice(0, 4), src.default, ...photoArray.slice(5)])
                }
                if(photoArray[5] === ''){
                    const src = await import('../../../images/storageInner2.jpeg')
                    setPhotoArray([...photoArray.slice(0, 5), src.default, ...photoArray.slice(6)])
                }
                if(photoArray[6] === ''){
                    const src = await import('../../../images/storeInner.jpeg')
                    setPhotoArray([...photoArray.slice(0, 6), src.default])
                }
                break;
            case 6:
                if(photoArray[5] === ''){
                    const src = await import('../../../images/storageInner2.jpeg')
                    setPhotoArray([...photoArray.slice(0, 5), src.default, ...photoArray.slice(6)])
                }
                if(photoArray[6] === ''){
                    const src = await import('../../../images/storeInner.jpeg')
                    setPhotoArray([...photoArray.slice(0, 6), src.default])
                }
                break;
        }
    }

    useEffect(()=>{
        lazyLoad()

        let galleryObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        })

        function animation(entries: Array<IntersectionObserverEntry>){
            entries.forEach((entry: IntersectionObserverEntry)=>{
                entry.isIntersecting && galleryRef.current && setIsVisible(true)
                entry.isIntersecting && galleryRef.current && galleryObserver.unobserve(galleryRef.current)
            })
        }

        galleryRef.current && galleryObserver.observe(galleryRef.current)

        let interval:any
        if(isSlideShowActive){
            interval = setInterval(()=>{
                nextPage()
            }, 2800)
        }
        return(()=>{
            clearInterval(interval)
        })
    })

    return(
        <>
        <div className='Gallery_main_container' onMouseOver={()=>setIsSlideShowActive(false)}
        onMouseLeave={()=>setIsSlideShowActive(true)} ref={galleryRef}>
            <div className={isVisible ? 'Gallery_photo_container' : 'Gallery_photo_container Gallery_photo_container_hidden'}>
                {photoArray.map((photo, index)=>{
                    return <GalleryPage index={index} photo={photo} 
                    isActive = {activePage === index}></GalleryPage>
                })}
            </div>
            <div className={isVisible ? 'Gallery_description_container' : 'Gallery_description_container Gallery_description_container_hidden'}>
                <div className='Gallery_description'>
                Современный мультитемпературный морозильный склад класса А, <span className='blue'>
                емкостью единовременного хранения 10000 т.</span> Состоит 
                из трех морозильных камер (-25°C), каждая площадью - 940 м², 
                по 2565 паллетомест стеллажного хранения, <span className='blue'>всего 7695 паллетомест.</span> Четвертая 
                холодильная камера, площадью 882 м² напольного 
                хранения, емкостью хранения - 2500 т. <span className='blue'>Температура хранения 
                до -25°C.</span> Температура в помещении 0°C. 
                Работают восемь ворот-докшелтеров. Централизованное холодоснабжение 
                обеспечивают одни из лучших японских компрессорных 
                агрегатов Mayekawa с 50% резервированием производительности. 
                Для организации оперативной погрузки/выгрузки используются 
                высотные штабелеры с выдвижной мачтой, вилочные электропогрузчики 
                и электротележки производства Linde.
                </div>
            </div>
        </div>
        <div className='Gallery_page_change_container'>
            <div className='change_page_button' id='change_page_button_prev' onClick={prevPage}>
                <img src={arrow} alt="arrow left" className='change_page_button_icon'/>
            </div>
            <div className='Gallery_pages'>
                {photoArray.map((item, index)=>{
                    return(
                        <div onClick={()=>setActivePage(index)}
                        className={(index)=== activePage ? 'Gallery_page_border_active' : 'Gallery_page_border'}>
                            <div className={(index)=== activePage ? 'Gallery_page_active' : 'Gallery_page'}></div>
                        </div>
                    )
                })}
                
            </div>
            <div className='change_page_button' id='change_page_button_next' onClick={nextPage}>
                <img src={arrow} alt="arrow right" className='change_page_button_icon'/>
            </div>
        </div>
        </>
    )
}