import { useEffect, useRef, useState } from 'react'
import './TextHeader.css'

interface ITextHeader{
    text: string,
    direction: 'Left' | 'Right',
    addText?: string
}

export default function TextHeader({text, direction, addText}:ITextHeader){
    const headerRef = useRef<HTMLDivElement>(null)
    const[isObserved, setIsObserved] = useState<boolean>(false)

    useEffect(()=>{
        let headerObserver = new IntersectionObserver(animation, {
            root: null,
            rootMargin: '0px',
            threshold: 1.00
        })

        function animation(entries:Array<IntersectionObserverEntry>){
            entries.forEach((entry:IntersectionObserverEntry) => {
                setIsObserved(entry.isIntersecting)
                entry.isIntersecting && headerRef.current && headerObserver.unobserve(headerRef.current)
            });
        }
    
        headerRef.current && headerObserver.observe(headerRef.current)
    }, [])

    return(
        <div className='TextHeader_container' style={headerRef.current ? {height: `${headerRef.current.scrollHeight}px`} : {height: `0px`}}>
            <div className={isObserved ? 'TextHeader' : `TextHeader to${direction}`} ref={headerRef}>{text}</div>
            {addText && <div className={isObserved ? 'addText' : `addText to${direction}`}>{addText}</div>}
        </div>
    )
}